import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import VideoPlayer from '../components/posts/VideoPlayer';

const AboutPage = () => (
  <Layout pageClass="page--bg-primary page--full">
    <SEO
      keywords={ [ ] }
      title="About"
    />

    <section className="about markdown">
      <div className="about__item">
        <p>
          One of the missions of the
          { ' ' }
          <a href="mailto: artsoffice@kaust.edu.sa" target="_blank" rel="noreferrer">Office of the Arts</a>
          { ' ' }
          is to ignite, showcase and sustain our global community of
          { ' ' }
          <a href="https://kaust.edu.sa/en" rel="nofollow">KAUST</a>
          { ' ' }
          creatives. During these unprecedented and challenging COVID-19 times, this mission
          is more important than ever.
        </p>
        <p>
          This is why we have created Culture in Quarantine (CinQ) whose home is here, as well as
          on
          { ' ' }
          <a href="https://www.instagram.com/kaustxcinq" rel="nofollow">Instagram</a>
          { ' ' }
          and
          { ' ' }
          <a href="https://www.facebook.com/officeofthearts/" rel="nofollow">Facebook</a>
          .
        </p>
        <p>
          To show and strengthen our collective resilience, to look at what is happening around us,
          to listen to people in our community, and learn and grow from our experiences.
        </p>
        <p>
          CinQ will serve as a legacy of positivity during this challenging period and will sharpen
          our understanding of the importance of arts and culture as an
          &quot;essential service&quot; and their contribution to our wellbeing.
        </p>
      </div>

      <div className="about__item">
        <p>
          KAUST is a unique international research community
          established in 2009 at the shores of the Red Sea.
        </p>
        <p>
          As a global destination for high-impact science and innovation,
          KAUST focuses on four areas of universal significance
          — food, water, energy and the environment.
        </p>
        <p>
          By inspiring discoveries to address broad challenges,
          we are a catalyst for innovation and economic development
          and social prosperity for Saudi Arabia and the world.
        </p>
        <p>
          Home to more than a hundred nationalities, KAUST has attracted,
          and produced, leading scientists across the world studying
          regionally important issues such as desert agriculture,
          Red Sea research, water desalination, solar energy and more.
        </p>
        <p>
          Envisioned by King Abdullah to be a new “House of Wisdom,” KAUST University
          aims to be a beacon for peace, hope, and reconciliation that bridges
          people and cultures for the betterment of humanity.
        </p>
      </div>

      <div className="about__item">
        <p>
          <strong>
            <a className="look" href="/look">LOOK</a>
          </strong>
          { ' ' }
          at what your fellow community members are creating
          and open your eyes to new possibilities. Join our neighbours on
          their doorsteps, discover the heroes and healers of COVID-19,
          and explore the work of our experienced artists and younger members alike.
        </p>
        <p>
          <strong>
            <a className="listen" href="/listen">LISTEN</a>
          </strong>
          { ' ' }
          to those around us; enjoy melodic self-expressions from our
          talented colleagues and friends. Let them take you to faraway
          times and places, all from a safe distance.
        </p>
        <p>
          <strong>
            <a className="learn" href="/learn">LEARN</a>
          </strong>
          { ' ' }
          from our knowledgeable and resourceful community.
          Pursue as diverse forms of connections as dance,
          cooking or yoga, all designed to help you cope just that
          little bit better at this moment and beyond.
        </p>
        <p>
          Let’s
          { ' ' }
          <strong>
            <a className="grow" href="/grow">GROW</a>
          </strong>
          { ' ' }
          together. By responding positively to the challenges and by
          rising through them, we will emerge better, stronger and more sharing than before.
        </p>
        <p className="text-center">
          <strong>Solkem N&apos;Gangbet</strong>
          <br />
          <small>Head of the Office of the Arts</small>
        </p>
      </div>
    </section>

    <div className="about__video"><VideoPlayer video="https://www.youtube.com/embed/Y_GKusKETCE" /></div>

  </Layout>
);

export default AboutPage;
